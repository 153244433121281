import { useState } from 'react';
import { Station, StationType } from '../../types/station';
import stationsService from '../../services/ScaleoAdminApiServices/StationsService';

export const useStationDialogs = (fetchStations: () => Promise<void>) => {
  const [selectedStations, setSelectedStations] = useState<Station[]>([]);
  const [station, setStation] = useState<Station>(emptyStation);
  const [stationDialogVisible, setStationDialogVisible] = useState(false);
  const [deleteStationDialogVisible, setDeleteStationDialogVisible] = useState(false);
  const [deleteStationsDialogVisible, setDeleteStationsDialogVisible] = useState(false);

  const openNew = () => {
    setStation(emptyStation);
    setStationDialogVisible(true);
  };

  const editStation = async (_station: Station) => {
    setStation({ ..._station });
    setStationDialogVisible(true);
  };

  const hideDeleteStationDialog = () => {
    setDeleteStationDialogVisible(false);
  };

  const confirmDeleteSelected = () => {
    setDeleteStationsDialogVisible(true);
  };

  const hideDeleteStationsDialog = () => {
    setDeleteStationsDialogVisible(false);
  };

  const deleteStation = () => {
    void stationsService.removeManyStations([station]).then(() => {
      void fetchStations();
    });
    setDeleteStationDialogVisible(false);
    setStation(emptyStation);
  };

  const deleteSelectedStations = () => {
    void stationsService.removeManyStations(selectedStations).then(() => {
      void fetchStations();
    });

    setDeleteStationsDialogVisible(false);
    setSelectedStations([]);
  };

  return {
    setSelectedStations,
    openNew,
    confirmDeleteSelected,
    hideDeleteStationDialog,
    deleteStation,
    deleteSelectedStations,
    editStation,
    hideDeleteStationsDialog,
    setStation,
    setDeleteStationDialogVisible,
    setStationDialogVisible,
    stationDialogVisible,
    deleteStationDialogVisible,
    deleteStationsDialogVisible,
    station,
    selectedStations,
  };
};

const emptyStation: Station = {
  id: null,
  name: '',
  installationId: '',
  locationId: '',
  edgeDeviceId: '',
  customerId: '',
  type: StationType.WEIGHING_STATION,
};
