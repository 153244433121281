import React, { useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

const dateFilterFunction = (value, filter) => {
  const formatDate = (date) => {
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);

    return date.getFullYear() + '-' + month + '-' + day;
  };

  if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
    return true;
  }

  if (value === undefined || value === null) {
    return false;
  }

  return formatDate(new Date(value)) === formatDate(filter);
};

const DateFilterElement = ({ initialValue, onChangeFilter, name, placeholder }) => {
  const [value, setValue] = useState(initialValue || null);

  const onChange = (e) => {
    if (e.value !== value) {
      setValue(e.value);
      onChangeFilter(e.value, name);
    }
  };

  const onClickClear = () => {
    setValue(null);
    onChangeFilter(null, name);
  };

  return (
    <div style={{ position: 'relative' }}>
      <Calendar
        value={value}
        onChange={onChange}
        dateFormat="dd.mm.y"
        className="p-column-filter"
        placeholder={placeholder}
        selectionMode="range"
        footerTemplate={() => <></>}
      />
      {value ? (
        <i
          onClick={onClickClear}
          style={{ color: '#6c757d', position: 'absolute', right: '8px', top: '9px', cursor: 'pointer' }}
          className="pi pi-times"
        />
      ) : (
        ''
      )}
    </div>
  );
};

const TextFilterElement = ({ initialValue, onChangeFilter, name, placeholder }) => {
  const [value, setValue] = useState(initialValue || '');
  const [valueAfterChange, setValueAfterChange] = useState(initialValue || '');
  const [showClearBtn, setShowClearBtn] = useState(false);

  useEffect(() => {
    setValue(initialValue || '');
    setValueAfterChange(initialValue || '');
  }, [initialValue]);

  const checkClearBtnVisible = () => {
    if (value) {
      setShowClearBtn(true);
    } else {
      setShowClearBtn(false);
    }
  };

  useEffect(() => {
    checkClearBtnVisible();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (valueAfterChange !== value) {
      setValueAfterChange(value);
      onChangeFilter(value, name);
      checkClearBtnVisible();
    }
  };

  const onKeyPress = (e) => {
    if (e.key === 'Enter' && valueAfterChange !== value) {
      setValueAfterChange(value);
      onChangeFilter(value, name);
      checkClearBtnVisible();
    }
  };

  const onClickClear = () => {
    setValue('');
    setValueAfterChange('');
    onChangeFilter('', name);
    checkClearBtnVisible();
  };

  return (
    <div style={{ position: 'relative' }}>
      <InputText
        type="text"
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        className="p-column-filter"
        style={{ paddingRight: '24px' }}
      />
      {showClearBtn ? (
        <i
          onClick={onClickClear}
          style={{ color: '#6c757d', position: 'absolute', right: '8px', top: '9px', cursor: 'pointer' }}
          className="pi pi-times"
        />
      ) : (
        ''
      )}
    </div>
  );
};

const DropdownFilterElement = ({ initialValue, onChangeFilter, name, placeholder, options }) => {
  const [value, setValue] = useState(initialValue || null);

  const onChange = (e) => {
    const newValue = e.value !== undefined ? e.value : null;
    if (newValue !== value) {
      setValue(newValue);
      onChangeFilter(newValue, name);
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <Dropdown
        value={value}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        className="p-column-filter"
        showClear
      />
    </div>
  );
};

const DataTableFilterElements = {
  Date: DateFilterElement,
  Text: TextFilterElement,
  Dropdown: DropdownFilterElement,
  dateFilterFunction,
};

export default DataTableFilterElements;
