import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { useRef } from 'react';
import { DeleteStationDialog } from './components/DeleteStationDialog';
import { DeleteStationsDialog } from './components/DeleteStationsDialog';
import { StationDialog } from './components/StationDialog';
import { useStationDialogs } from '../../hooks/Stations/useStationDialogs';
import { useStationsData } from '../../hooks/Stations/useStationsData';
import { Station } from '../../types/station';
import { CustomButton, CustomTableField } from '../_shared/CustomComponents';
import DataTableFilterElements from '../_shared/DataTableFilterElements';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';

export const Stations = () => {
  const dt = useRef(null);

  const {
    fetchStations,
    stations,
    handleSort,
    paginationParameters,
    paginatorState,
    setPaginatorState,
    internalFilters,
    changeFilter,
    clearFilters,
    locations,
    filters,
    customers,
  } = useStationsData();

  const {
    setSelectedStations,
    openNew,
    confirmDeleteSelected,
    hideDeleteStationDialog,
    deleteStation,
    deleteSelectedStations,
    editStation,
    hideDeleteStationsDialog,
    setStation,
    setDeleteStationDialogVisible,
    setStationDialogVisible,
    stationDialogVisible,
    deleteStationDialogVisible,
    deleteStationsDialogVisible,
    station,
    selectedStations,
  } = useStationDialogs(fetchStations);

  const header = (
    <div className="table-header">
      {'Stanowiska'} &#160;&#160;
      <div className="p-text-right">
        <Button
          label={'Wyczyść'}
          icon="pi pi-filter-slash"
          className="p-button-outlined p-mr-2"
          onClick={clearFilters}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <DataTableFilterElements.Text
            initialValue={internalFilters['global']}
            onChangeFilter={changeFilter}
            name={'global'}
            placeholder={'Wyszukaj'}
          />
        </span>
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label={'Dodaj'} icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
        <CustomButton
          label={'Usuń'}
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedStations || !selectedStations.length}
          tooltip={!selectedStations.length ? 'Wybierz' : 'Nie można usunąć'}
          name={undefined}
          dataFlag={undefined}
          type={undefined}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData: Station) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editStation(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-secondary"
          onClick={() => {
            setStation(rowData);
            setDeleteStationDialogVisible(true);
          }}
        />
      </div>
    );
  };

  const columnBodyTemplate = (rowData: Station, column: any) => {
    return <CustomTableField row={rowData} col={column} withoutHeaders={undefined} />;
  };

  const locationBodyTemplate = (rowData: Station) => (
    <>
      <span className="p-column-title">{'Oddział'}</span>
      {locations?.find((l) => l.id === rowData.locationId)?.name}
    </>
  );

  const customerBodyTemplate = (rowData: Station) => {
    const location = locations?.find((l) => l.id === rowData.locationId);

    return (
      <>
        <span className="p-column-title">{'Klient'}</span>
        {customers?.find((c) => c.id === location?.customer?.id)?.displayName}
      </>
    );
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                ref={dt}
                value={stations ?? []}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                rowHover
                selection={selectedStations}
                selectionMode="checkbox"
                onSelectionChange={(e) => setSelectedStations(e.value)}
                emptyMessage={'Brak danych'}
                removableSort
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column
                  key={'name'}
                  field={'name'}
                  header={'Nazwa'}
                  body={columnBodyTemplate}
                  fieldsType={'text'}
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={internalFilters['name']}
                      onChangeFilter={changeFilter}
                      name={'name'}
                      placeholder={'Filtruj'}
                    />
                  }
                />
                <Column
                  key={'installationId'}
                  field={'installationId'}
                  header={'Identyfikator instalacji'}
                  body={columnBodyTemplate}
                  fieldsType={'text'}
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={internalFilters['installationId']}
                      onChangeFilter={changeFilter}
                      name={'installationId'}
                      placeholder={'Filtruj'}
                    />
                  }
                />
                <Column
                  field="customerName"
                  header={'Klient'}
                  sortable
                  body={customerBodyTemplate}
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters['customerName']}
                      onChangeFilter={changeFilter}
                      name="customerName"
                      placeholder={'filtruj'}
                    />
                  }
                  filterPlaceholder={'Wyszkuj przez klienta'}
                />
                <Column
                  field="locationId"
                  header={'Oddział'}
                  sortable
                  body={locationBodyTemplate}
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters['locationName']}
                      onChangeFilter={changeFilter}
                      name="locationName"
                      placeholder={'filtruj'}
                    />
                  }
                  filterPlaceholder={'Wyszkuj przez oddział'}
                />
                <Column body={actionBodyTemplate} style={{ width: '100px', textAlign: 'center' }}></Column>
              </DataTable>
              <StationDialog
                visible={stationDialogVisible}
                onStationAdded={() => fetchStations()}
                setStationDialogVisible={setStationDialogVisible}
                station={station}
                locations={locations}
                customers={customers}
              />
              <DeleteStationDialog
                deleteStationDialogVisible={deleteStationDialogVisible}
                hideDeleteStationDialog={hideDeleteStationDialog}
                station={station}
                deleteStation={deleteStation}
              />
              <DeleteStationsDialog
                deleteStationsDialogVisible={deleteStationsDialogVisible}
                hideDeleteStationsDialog={hideDeleteStationsDialog}
                deleteSelectedStations={deleteSelectedStations}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Stations;
