import axios from 'axios';

class CustomerUsersService {
  async searchCustomerUsers(filters, paginatorParam, globalFilter) {
    return axios.post('/customer-users/search', { filters, paginatorParam, globalFilter });
  }
  async inviteCustomerUser(user) {
    return axios.post('/customer-users/invitation', user);
  }
  async resendInvitationCustomerUser(params) {
    return axios.post('/customer-users/resend-invitation', params);
  }
  async editCustomerUser(user, id) {
    return axios.put(`/customer-users/${id}`, user);
  }
  async deleleCustomerUser(id) {
    return axios.delete(`/customer-users/${id}`);
  }
}
const customerUsersService = new CustomerUsersService();
export default customerUsersService;
