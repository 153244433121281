import { useState, useCallback } from 'react';

import customersService from '../../services/ScaleoAdminApiServices/CustomersService';

const useOnlineCustomerWithLicenseData = () => {
  const [data, setData] = useState([]);

  const fetchOnlineCustomerWithLicenseData = useCallback(async () => {
    const onlineCustomersWithLicenses = await customersService.getOnlineCustomersWithLicenses();

    if (onlineCustomersWithLicenses) {
      setData(onlineCustomersWithLicenses.data);
    }
  }, []);

  return {
    data,
    fetchOnlineCustomerWithLicenseData,
  };
};

export default useOnlineCustomerWithLicenseData;
