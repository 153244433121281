export const getTranslatedPermissions = (permissionsId: string) => {
  switch (permissionsId) {
    case 'ADMIN_USERS_MANAGEMENT':
      return 'Zarządzanie administracją';
    case 'ROLES_MANAGEMENT':
      return 'Zarządzanie rolami';
    case 'ACCESS_TO_EVENT_LOG':
      return 'Dostęp do dziennika zdarzeń';
    case 'CUSTOMER_MANAGEMENT':
      return 'Zarządzanie klientami oraz licencjami';
    case 'SERVICE_USERS_MANAGEMENT':
      return 'Zarządzanie serwisantami';
    case 'SCALEO_USERS_MANAGEMENT':
      return 'Zarządzanie użytkownikami Scaleo';
    case 'NODE_MODELS_MANAGEMENT':
      return 'Zarządzanie konfiguracją urządzeń';
    case 'DEPLOYMENTS_MANAGEMENT':
      return 'Zarządzanie aktualizacjami';
    case 'STATIONS_MANAGEMENT':
      return 'Zarządzanie stacjami';
    case 'LOCATIONS_MANAGEMENT':
      return 'Zarządzanie oddziałami';
    default:
      return permissionsId;
  }
};
