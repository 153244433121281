import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { SplitButton } from 'primereact/splitbutton';
import { InputTextarea } from 'primereact/inputtextarea';
import { Message } from 'primereact/message';
import React, { useEffect, useState } from 'react';
import '../DataTable.css';
import { v4 as uuidv4 } from 'uuid';
import nodeModelService from '../../services/ScaleoAdminApiServices/NodeModelsService';
import { socketCommandTemplate, restCommandTemplate } from './commandTemplates';

const requiredCommands = {
  WEIGHT_INDICATOR: ['get_weight', 'save'],
  DISPLAY: [],
};

const INIT_FORM_STATE = {
  name: '',
  manufacturer: '',
  description: '',
  node_type: null,
  protocol: { commands: [] },
};

const nodeModelTypes = [
  {
    value: 'WEIGHT_INDICATOR',
    label: 'Waga',
  },
  {
    value: 'DISPLAY',
    label: 'Wyświetlacz',
  },
];

const NewOrEditNodeModelDialog = ({ visible, closeDialog, item }) => {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const [expandedRows, setExpandedRows] = useState(null);

  useEffect(() => {
    if (visible && item?.id) {
      const { name, manufacturer, description, node_type, protocol } = item;
      setInitFormValues({ name, manufacturer, description, node_type, protocol });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
  }, [item, visible]);

  const validateMethod = async (data) => {
    const errors = {};
    if (!data.name) {
      errors.name = 'Podaj nazwe';
    }
    if (!data.node_type) {
      errors.node_type = 'Podaj typ';
    }

    const commandNames = data.protocol?.commands.map((command) => command.name);
    if (requiredCommands[data.node_type]?.some((required) => !commandNames.includes(required))) {
      errors.protocol = `Brak wymaganych lub niepoprawne komendy (wymagane komendy: ${requiredCommands[
        data.node_type
      ].join(', ')})`;
    }

    return errors;
  };

  const handleCloseDialog = (changes) => {
    formik.resetForm({
      values: initFormValues,
    });
    closeDialog(changes === true);
  };

  const handleSubmit = (formData) => {
    if (item?.id) {
      void nodeModelService.editNodeModel(formData, item.id).then(() => {
        handleCloseDialog(true);
      });
    } else {
      void nodeModelService.createNodeModel(formData, requiredCommands[formData.node_type]).then(() => {
        handleCloseDialog(true);
      });
    }
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const isFormFieldInvalid = (name) => formik.touched[name] && formik.errors[name];

  const getFormErrorMessage = (name) =>
    isFormFieldInvalid(name) && <Message severity="error" text={formik.errors[name]} />;

  const dialogFooter = (
    <>
      <Button type="reset" label="Anuluj" icon="pi pi-times" className="p-button-text" onClick={handleCloseDialog} />
      <Button type="submit" label="Zapisz" icon="pi pi-check" className="p-button-text" onClick={formik.submitForm} />
    </>
  );

  const changeCommand = async (rowId, field, e) => {
    var commandLp = null;
    for (const [index, command] of formik.values.protocol.commands.entries()) {
      if (command.id === rowId) {
        commandLp = index;
        break;
      }
    }
    void formik.setFieldValue(`protocol.commands[${commandLp}][${field.name}]`, e.target.value);
  };

  const rowExpansionTemplate = (rowData) => {
    let currentTemplate = null;
    if (rowData.url) currentTemplate = restCommandTemplate;
    else currentTemplate = socketCommandTemplate;

    return (
      <>
        <div className="p-fluid p-col-10">
          {currentTemplate?.map((field) => {
            return (
              <div key={field.id} className="p-field">
                <label htmlFor={field.id}>{field.label}</label>
                {field.dropdown ? (
                  <Dropdown
                    value={rowData[field.name]}
                    options={field.source}
                    onChange={(e) => {
                      void changeCommand(rowData.id, field, e);
                    }}
                    optionLabel="name"
                  />
                ) : field.rowsCount ? (
                  <InputTextarea
                    id={field.id}
                    value={rowData[field.name]}
                    onChange={(e) => {
                      void changeCommand(rowData.id, field, e);
                    }}
                    rows={field.rowsCount}
                    autoResize
                  />
                ) : (
                  <InputText
                    id={field.id}
                    value={rowData[field.name]}
                    onChange={(e) => {
                      void changeCommand(rowData.id, field, e);
                    }}
                  />
                )}
              </div>
            );
          })}
        </div>
      </>
    );
  };
  const deleteCommand = (commandId) => {
    const reducedCommands = formik.values.protocol?.commands.filter((item) => item.id !== commandId);
    void formik.setFieldValue(`protocol.commands`, reducedCommands);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <Button
        type="reset"
        icon="pi pi-trash"
        className="p-button-rounded p-button-danger"
        onClick={() => deleteCommand(rowData.id)}
      />
    );
  };
  const triggerButton = (self) => {
    self.currentTarget.nextSibling.click();
  };

  const addNewCommand = (type) => {
    const newCommandId = uuidv4();
    switch (type) {
      case 'socket':
        void formik.setFieldValue(`protocol.commands`, [
          ...formik.values.protocol?.commands,
          { id: newCommandId, name: '', description: '', command: '', response_regex: '' },
        ]);
        break;
      case 'rest':
        void formik.setFieldValue(`protocol.commands`, [
          ...formik.values.protocol?.commands,
          { id: newCommandId, name: '', description: '', method: '', url: ' ', response_jsonpath: '' },
        ]);
        break;
      default:
        console.log('Button not working');
    }
    setExpandedRows({ ...expandedRows, [newCommandId]: true });
  };

  return (
    <Dialog
      visible={visible}
      header="Szczegóły urządzenia"
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={handleCloseDialog}
      breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
      style={{ width: '40vw' }}
    >
      <form>
        <div className="p-col-10">
          <div className="p-field">
            <label htmlFor="name">Nazwa</label>
            <InputText id="name" value={formik.values.name} onChange={formik.handleChange} />
            {getFormErrorMessage('name')}
          </div>
          <div className="p-field">
            <label htmlFor="node_type">Typ</label>
            <Dropdown
              id="node_type"
              value={formik.values.node_type}
              options={nodeModelTypes}
              onChange={formik.handleChange}
              placeholder="Wybierz typ"
            />
            {getFormErrorMessage('node_type')}
          </div>
          <div className="p-field">
            <label htmlFor="manufacturer">Producent</label>
            <InputText id="manufacturer" value={formik.values.manufacturer} onChange={formik.handleChange} />
            {getFormErrorMessage('manufacturer')}
          </div>
          <div className="p-field">
            <label htmlFor="description">Opis</label>
            <InputTextarea
              id="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              rows={5}
              cols={30}
              autoResize
            />
            {getFormErrorMessage('description')}
          </div>
        </div>
        <DataTable
          value={formik.values.protocol?.commands}
          editMode="row"
          dataKey="id"
          responsiveLayout="scroll"
          rowExpansionTemplate={rowExpansionTemplate}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          emptyMessage="Brak komend"
        >
          <Column expander style={{ width: '5%' }} />
          <Column field="name" header="Nazwa" style={{ width: '17%' }}></Column>
          <Column field="description" header="Opis" style={{ width: '65%' }}></Column>
          <Column body={actionBodyTemplate} className="actionBody"></Column>
        </DataTable>
        {getFormErrorMessage('protocol')}
      </form>

      <div className="p-col-4">
        <SplitButton
          className="p-button-info my-split-button"
          label="Dodaj komendę"
          icon="pi pi-chevron-down"
          iconPos="right"
          model={[
            {
              label: 'Socket',
              command: () => {
                addNewCommand('socket');
              },
            },
            {
              label: 'REST',
              command: () => {
                addNewCommand('rest');
              },
            },
          ]}
          onClick={triggerButton}
        />
      </div>
    </Dialog>
  );
};

export default NewOrEditNodeModelDialog;
