import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import React, { useEffect, useState } from 'react';
import customersService from '../../services/ScaleoAdminApiServices/CustomersService';
import '../DataTable.css';
import { InputTextarea } from 'primereact/inputtextarea';
import { useDebounce } from 'use-debounce';
import { countryOptionTemplate, selectedCountryTemplate } from '../../utils/LanguageSelectorUtils';
import { Dropdown } from 'primereact/dropdown';
import { AVAILABLE_LANGUAGES } from '../../utils/languages-config';

const INIT_FORM_STATE = {
  companyName: null,
  firstName: null,
  lastName: null,
  taxId: null,
  defaultUserLanguage: null,
  autoGeneratedDocumentsLanguage: null,
  remarks: null,
};

interface NewOrEditCustomerDialogProps {
  closeDialog: Function;
  item: any;
}

interface FormWarnings {
  companyName?: string;
  firstName?: string;
  lastName?: string;
  taxId?: string;
  defaultUserLanguage?: string;
  autoGeneratedDocumentsLanguage?: string;
  remarks?: string;
}

const NewOrEditCustomerDialog = ({ closeDialog, item }: NewOrEditCustomerDialogProps) => {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);
  const [warnings, setWarnings] = useState<FormWarnings>({});

  useEffect(() => {
    if (item?.id) {
      const { companyName, firstName, lastName, taxId, remarks, defaultUserLanguage, autoGeneratedDocumentsLanguage } =
        item;
      setInitFormValues({
        companyName,
        firstName,
        lastName,
        taxId,
        defaultUserLanguage,
        autoGeneratedDocumentsLanguage,
        remarks,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
      setWarnings({});
    }
  }, [item]);

  const validateMethod = (data: any) => {
    const errors = {} as any;
    if (data.companyName && !data.companyName.match(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ. 0-9-"'„]+$/)) {
      errors.companyName =
        'Nazwa może składać się z dużych i małych liter, cyfr, spacji i znaków "-" lub ".", cudzysłowu lub apostrofu';
    }

    if (!data.taxId) {
      errors.taxId = 'NIP jest wymagany';
    }

    if (!data.defaultUserLanguage) {
      errors.defaultUserLanguage = 'Domyślny język dla użytkownika jest wymagany';
    }

    if (!data.autoGeneratedDocumentsLanguage) {
      errors.autoGeneratedDocumentsLanguage = 'Język automatycznie generowanych dokumentów jest wymagany';
    }

    return errors;
  };

  const handleCloseDialog = (changes: boolean) => {
    formik.resetForm({
      values: initFormValues,
    });
    closeDialog(changes === true);
  };

  const handleSubmit = (data: any, helpers: any) => {
    const body = data;

    if (item?.id) {
      customersService
        .editCustomer(body, item.id)
        .then(() => {
          handleCloseDialog(true);
        })
        .catch((error) => {
          if (error.response.status === 409) {
            const errorCode = error.response.data.error;
            errorCode === 'CUSTOMER_COMPANY_NAME_EXISTS' &&
              helpers.setFieldError('companyName', 'Klient o podanej nazwie już istnieje.');
          }
        });
    } else {
      customersService
        .postCustomer(body)
        .then(() => {
          handleCloseDialog(true);
        })
        .catch((error) => {
          if (error.response.status === 409) {
            const errorCode = error.response.data.error;
            errorCode === 'CUSTOMER_COMPANY_NAME_EXISTS' &&
              helpers.setFieldError('companyName', 'Firma o podanej nazwie już istnieje w systemie.');
          }
        });
    }
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const [taxIdToCheck] = useDebounce(formik.values.taxId, 1000);

  useEffect(() => {
    const checkTaxId = async () => {
      if (taxIdToCheck && taxIdToCheck !== '') {
        void formik.setFieldTouched('taxId', true, true);

        const taxIdExists = await customersService.checkTaxIdExistence(taxIdToCheck, item?.id ? item?.id : '');

        if (taxIdExists) {
          setWarnings((prevWarnings) => ({
            ...prevWarnings,
            taxId: 'Uwaga! Podany numer NIP znajduje się już w systemie.',
          }));
        } else {
          setWarnings((prevWarnings) => {
            const newWarnings: any = { ...prevWarnings };
            delete newWarnings?.taxId;
            return newWarnings;
          });
        }
      }
    };

    void checkTaxId();
  }, [taxIdToCheck, item?.id]);

  const dialogFooter = (
    <>
      <Button
        type="reset"
        label="Anuluj"
        icon="pi pi-times"
        className="p-button-text"
        onClick={handleCloseDialog as any}
      />
      <Button type="submit" label="Zapisz" icon="pi pi-check" className="p-button-text" onClick={formik.submitForm} />
    </>
  );

  const isFormFieldInvalid = (name: keyof typeof formik.touched) => formik.touched[name] && formik.errors[name];

  const getFormErrorMessage = (name: keyof typeof formik.touched) =>
    isFormFieldInvalid(name) && <Message severity="error" text={formik.errors[name] as any} />;

  const getFormWarningMessage = (name: keyof typeof formik.touched) =>
    formik.touched[name] && warnings[name] ? <Message severity="warn" text={warnings[name]} /> : null;

  const handleChangeField = (e: any, type: string) => {
    switch (type) {
      case 'text':
        void formik.setFieldValue(e.target.id, e.target.value, true);
        break;

      case 'dropdown':
        void formik.setFieldValue(e.target.id, e.value, false);
        break;

      case 'chips':
        void formik.setFieldValue(e.target.id, e.value, true);
        break;

      default:
        void formik.handleChange(e);
        break;
    }
  };

  return (
    <div className="customer-edit-dialog">
      <Dialog
        visible={true}
        header="Szczegóły klienta"
        modal
        className="p-fluid"
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
        footer={dialogFooter}
        onHide={handleCloseDialog as any}
      >
        <form>
          <div className="p-col-10">
            <div className="p-field">
              <label htmlFor="companyName">Nazwa Firmy</label>
              <InputText
                id="companyName"
                value={formik.values.companyName}
                onChange={(e) => handleChangeField(e, 'text')}
              />
              {getFormErrorMessage('companyName')}
            </div>
            <div className="p-field">
              <label htmlFor="firstName">Imię</label>
              <InputText
                id="firstName"
                value={formik.values.firstName}
                onChange={(e) => handleChangeField(e, 'text')}
              />
              {getFormErrorMessage('firstName')}
            </div>
            <div className="p-field">
              <label htmlFor="lastName">Nazwisko</label>
              <InputText id="lastName" value={formik.values.lastName} onChange={(e) => handleChangeField(e, 'text')} />
              {getFormErrorMessage('lastName')}
            </div>
            <div className="p-field">
              <label htmlFor="taxId">NIP</label>
              <InputText id="taxId" value={formik.values.taxId} onChange={(e) => handleChangeField(e, 'text')} />
              {getFormWarningMessage('taxId')}
              {getFormErrorMessage('taxId')}
            </div>
            <div className="p-field">
              <label htmlFor="defaultUserLanguage">Domyślny język dla użytkownika</label>
              <Dropdown
                value={formik.values.defaultUserLanguage}
                options={AVAILABLE_LANGUAGES}
                onChange={(e: any) => {
                  void formik.setFieldValue('defaultUserLanguage', e.value);
                }}
                required
                itemTemplate={countryOptionTemplate}
                valueTemplate={selectedCountryTemplate}
                className="language-selector"
              />
              {getFormErrorMessage('defaultUserLanguage')}
            </div>
            <div className="p-field">
              <label htmlFor="autoGeneratedDocumentsLanguage">Język automatycznie generowanych dokumentów</label>
              <Dropdown
                value={formik.values.autoGeneratedDocumentsLanguage}
                options={AVAILABLE_LANGUAGES}
                onChange={(e: any) => {
                  void formik.setFieldValue('autoGeneratedDocumentsLanguage', e.value);
                }}
                required
                itemTemplate={countryOptionTemplate}
                valueTemplate={selectedCountryTemplate}
                className="language-selector"
              />
              {getFormErrorMessage('autoGeneratedDocumentsLanguage')}
            </div>
            <div className="p-field">
              <label htmlFor="remarks">Uwagi</label>
              <InputTextarea
                autoResize
                id="remarks"
                value={formik.values.remarks}
                onChange={(e) => handleChangeField(e, 'text')}
              />
            </div>
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default NewOrEditCustomerDialog;
